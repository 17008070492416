<template>
    <loader v-bind="{ loading }" text="Loading Contacts">
        <columns>
            <column class="is-narrow">

            </column>
            <column>
                <form>
                    <text-input v-model="filters.search" classes="has-addons is-rounded"
                        placeholder="Search for contacts...">
                        <template #right>
                            <div class="buttons has-addons is-rounded">
                                <submit-button @submit="runSearch" class="is-rounded">
                                    Search
                                </submit-button>
                                <action-button v-if="$route.query.search" @click="clearFilters">
                                    <icon icon="times" />
                                </action-button>
                                <router-link class="button is-rounded" :to="{
                                    name: 'create-customer-contact',
                                    params: {
                                        customer: customer.uuid
                                    }
                                }">
                                    <icon icon="plus" />
                                    <span>Contact</span>
                                </router-link>
                            </div>
                        </template>
                    </text-input>
                </form>
            </column>
        </columns>
        <columns>
            <column>
                <div class="box" v-for="contact in contacts.data" :key="contact.id">
                    <columns>
                        <column>
                            <router-link :to="{
                                name: 'edit-customer-contact',
                                params: {
                                    contact: contact.uuid
                                }
                            }">
                                {{ contact.full_name }}
                            </router-link>
                            <small v-if="contact.position">
                                <br>
                                {{ contact.position }}
                            </small>
                        </column>
                        <column class="is-one-fifth-tablet is-two-quarters-mobile is-flex is-align-items-center is-justify-content-end">
                            <index-info 
                            icon="building-flag" 
                            label="Locations" 
                            :info="contact.locations_count"
                            />
                        </column>
                    </columns>
                </div>
                <pager v-if="contacts.data.length" :pageable="contacts" context="contact" jump-controls @nav="goToPage" />

                <no-items-to-display :item-count="contacts.data.length"
                    heading="There are no contacts available for display." />
            </column>
        </columns>
    </loader>
</template>
<script>
import { get } from '@/api/request'
import { mapGetters } from 'vuex'

export default {

    data: () => ({
        loading: true,
        filters: {
            search: ''
        },
        contacts: {
            data: []
        }
    }),

    created() {
        if (this.$route.query) {
            this.filters.search = this.$route.query.search
        }
        this.loadContacts()
    },

    methods: {
        loadContacts() {
            get('v1' + this.$route.fullPath, ({ data }) => {
                this.contacts = data
                this.loading = false
            }, () => {
                this.loading = false
            })
        },
        goToPage(path) {
            this.$router.push(path)
        },
        runSearch() {
            this.$router.push({
                name: 'customer-contacts',
                params: {
                    customer: this.$route.params.customer
                },
                query: this.filters
            });
        },
        clearFilters() {
            this.filters.search = ''
            this.$router.push({
                params: {
                    customer: this.$route.params.customer
                },
                name: 'customer-contacts',
            });
        },
    },

    computed: {
        ...mapGetters('customer', [
            'customer'
        ])
    },

    watch: {
        '$route': 'loadContacts'
    }

}
</script>